<template>
  <div class="patientDetail">
    <PatientDetail v-if="!isEmrDoctor" backRoute="OrgOwnerPatientDetail" />
    <PatientDetail2 v-else backRoute="OrgOwnerPatientDetail" />
    <PatientTreatmentList v-if="!isEmrDoctor" treatmentHistoryDetailRoute="OrgOwnerPatientTreatmentDetail" />
    <PatientTreatmentList2 v-else treatmentHistoryDetailRoute="OrgOwnerPatientTreatmentDetail" />
  </div>
</template>

<script>
import PatientDetail from "@/views/components/patients/Detail";
import PatientDetail2 from "@/views/components/patients/Detail2";
import PatientTreatmentList from "@/views/components/patients/PatientTreatmentList";
import PatientTreatmentList2 from "@/views/components/patients/PatientTreatmentList2";

export default {
  components: {
    PatientDetail,
    PatientDetail2,
    PatientTreatmentList,
    PatientTreatmentList2
  },
  computed: {
    isEmrDoctor() {
      return JSON.parse(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)).doctorEMRClinics.length
    }
  },
  beforeRouteLeave(to, from, next) {
    const popup = document.getElementById("editPatientDetailsDrawer");
    const popup2 = document.getElementById("createNewPatientTreatment");
    if (popup && window.getComputedStyle(popup).display !== "none"){
      next(false);
    }
    else if (popup2 && window.getComputedStyle(popup2).display !== "none"){
      next(false);
    }
    else {
      next();
    }
  },
};
</script>
